export interface IKeyStringValueNumber {
    [key: string]: number;
}

export const CONSUMPTION_KEY: string = '0006';
export const MOTORIZATION_KEY: string = '0001';
export const PERFORMANCE_KEY: string = '0005';
export const CONSUMPTION_KEY_WLTP: string = '9111';
export const EXTERIOR_DIMENSIONS_SECTION_KEY: any = {
    ['fr_AP']: '0002',
    ['fr_AC']: '0002',
    ['fr_DS']: '0002',
    ['fr_OV']: '0002',
    ['it_AP']: '0002',
    ['it_AC']: '0002',
    ['it_DS']: '0002',
    ['es_AP']: '0012',
    ['es_AC']: '0002',
    ['es_DS']: '0002',
    ['es_OV']: '0012',
    ['pt_AP']: '9001',
    ['pt_AC']: '0009',
    ['gb_AC']: '0008',
    ['gb_DS']: '0008',
    ['gb_OV']: '0002',
};
export const EXTERIOR_DIMENSIONS_IMAGE_TYPE: string = 'dimensions';
export const CONSUMPTION_KEY_ORDER: number = 4;
export const GALON_IN_LITRES: number = 4.54609;
export const MILE_IN_KILOMETRES: number = 1.609344;
export const STANDARD_FEATURES_FS_PREFIX: string = 'SF';
export const TECHNICAL_CHARACTERISTICS_FS_PREFIX: string = 'TC';

export const PROMOTED_SPECS_ORDER: IKeyStringValueNumber = {
    [MOTORIZATION_KEY]: 1,
    [PERFORMANCE_KEY]: 2,
    [CONSUMPTION_KEY_WLTP]: 3,
};

// message id
export const FUEL: string = 'fuel';
export const GEARBOX: string = 'gearbox';
export const ENGINE_POWER: string = 'engine.power';
export const CONSUMPTION_MIX: string = 'consumption.mix';
export const CONSUMPTION_CO2: string = 'consumption.co2';
export const ELECTRIC_RANGE: string = 'consumption.electricRange';
export const ELECTRIC_CONSUMPTION: string = 'consumption.electricConsumption';

// data keys
export const CONSUMPTION_MIX_KEY: string = 'consumptionCombined';
export const CONSUMPTION_CO2_KEY: string = 'co2Combined';
export const POWER_KW_KEY: string = 'power';
export const ELECTRIC_RANGE_KEY: string = 'electricRange';
export const ELECTRIC_CONSUMPTION_KEY: string = 'electricConsumption';

export const MAXIMUM_POWER_KW = 'maximumPowerKW';
export const FISCAL_HORSE_POWER = 'fiscalHorsePower';

export const NO_VALUE_DASH: string = '-';

// images
export const EMISSIONS_IMG: string = 'emissions';
export const FUEL_IMG: string = 'fuel';
export const MANUAL_IMG: string = 'manual';

export const ENVIRONMENTAL_IMAGES: string[] = [EMISSIONS_IMG, FUEL_IMG, MANUAL_IMG];

export const ELECTRIC_FUEL: string = 'Electrique';

export enum CONSUMPTION {
    CO2 = 'consumption.co2',
    CO2_COMBINED = 'consumption.co2Combined',
    CONSUMPTION_COMBINED = 'consumption.consumptionCombined',
    CYCLE_E_HIGH = 'consumption.cycleEhigh',
    CYCLE_HIGH = 'consumption.cycleHigh',
    CYCLE_LOW = 'consumption.cycleLow',
    CYCLE_MID = 'consumption.cycleMid',
    ELECTRIC_CONSUMPTION = 'consumption.electricConsumption',
    ELECTRIC_RANGE = 'consumption.electricRange',
    FUEL = 'consumption.fuel',
    LITER_PER_KM = 'consumption.literPerKM',
    MIX = 'consumption.mix',
    MPG = 'consumption.mpg',
}

export enum ENGINE {
    FUEL = 'engine.fuel',
    POWER = 'engine.power',
}
